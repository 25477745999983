/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Box, List, ListItem
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';

import utility from 'APP/utility';
import statusConfig from 'APP/configs/statusConfig';
import editConfig from 'APP/configs/editConfig';

// log field 顯示名稱可參考 APP/configs/editConfig 中的 logFieldName
const manageLogData = (logData = {}, cancelReasonMapping = {}) => {

    const data = {};

    // 貨態狀態
    if (logData?.package?.status !== undefined) data.packageStatus = logData?.package?.status;
    // 倉庫, 貨架
    if (logData?.package?.storehouseId !== undefined) data.storehouse = logData?.package?.storehouseId;
    if (logData?.package?.shelvesId !== undefined) data.shelves = logData?.package?.shelvesId;
    // rfid
    if (logData?.package?.rfid !== undefined) data.rfid = logData?.package?.rfid;
    // 物流商 ( 寄給買家 )
    logData?.shippingInfo?.shippingName && (data.shippingName = logData?.shippingInfo?.shippingName);
    logData?.shippingInfo?.shippingNumber && (data.shippingNumber = logData?.shippingInfo?.shippingNumber);
    // 物流商 ( 寄給賣家 )
    logData?.revertShippingInfo?.shippingName && (data.revertShippingName = logData?.revertShippingInfo?.shippingName);
    logData?.revertShippingInfo?.shippingNumber && (data.revertShippingNumber = logData?.revertShippingInfo?.shippingNumber);
    // 異常狀態（ 下拉選單選擇的 ）
    logData?.cancelReason?.type && (data.cancelType = logData?.cancelReason?.type);
    // 異常原因（ 如果 reason 內容是因為選了下拉選單的選項而被系統塞入與所選選項一樣的值時, 不顯示 ）
    (logData?.cancelReason?.reason && logData?.cancelReason?.reason !== cancelReasonMapping[logData?.cancelReason?.type]) && (data.cancelReason = logData?.cancelReason?.reason);
    
    logData?.cancelReason?.detailType && (data.detailType = logData?.cancelReason?.detailReason);
    // logData?.cancelReason?.detailReason && (data.detailReason = logData?.cancelReason?.detailReason);

    logData?.customsInfo?.originCountry && (data.originCountry = logData?.customsInfo?.originCountry);

    logData?.customsInfo?.productionType && (data.productionType = logData?.customsInfo?.productionType);

    logData?.customsInfo?.weight && (data.weight = logData?.customsInfo?.weight);

    logData?.customsInfo?.productionCustomsCode && (data.productionCustomsCode = logData?.customsInfo?.productionCustomsCode);

    if (logData?.customsInfo?.packageSize && logData?.customsInfo?.packageSize !== 'other') {

        data.packageSize = logData?.customsInfo?.packageSize.replace(/_/g, ' x ');
    
    }
    else if (logData?.customsInfo?.length && logData?.customsInfo?.width && logData?.customsInfo?.hight) {

        data.packageSize = `${logData?.customsInfo?.length} x ${logData?.customsInfo?.width} x ${logData?.customsInfo?.hight}`;

    }

    logData?.insurance?.code && (data.insuranceCode = logData?.insurance?.code);

    // 備註
    if (logData?.package?.adminMemo !== undefined) data.adminMemo = logData?.package?.adminMemo;

    // 收件人地址
    if (logData?.recipientInfo?.phone !== undefined) data.recipientPhone = logData?.recipientInfo?.phone;
    if (logData?.recipientInfo?.zipcode !== undefined) data.recipientZipcode = logData?.recipientInfo?.zipcode;
    if (logData?.recipientInfo?.name !== undefined) data.recipientName = logData?.recipientInfo?.name;
    if (logData?.recipientInfo?.city !== undefined) data.recipientCity = logData?.recipientInfo?.city;
    if (logData?.recipientInfo?.district !== undefined) data.recipientDistrict = logData?.recipientInfo?.district;
    if (logData?.recipientInfo?.address !== undefined) data.recipientAddress = logData?.recipientInfo?.address;
    if (logData?.wmsInfo?.area02Memo !== undefined) data.wmsInfoArea02Memo = logData?.wmsInfo?.area02Memo;

    return data;

};

function ActionLog ({
    logs,
    cancelReasonMapping,
    // shippingTypeMapping,
    storehouseMapping,
    shelvesMapping
}) {

    if (logs.length === 0) return null;
    
    return (
        <List
            sx={{
                minWidth: '300px',
                maxWidth: '500px',
                padding: '20px 0 20px 18px',
                height: 'calc(100vh - 3em)',
                overflow: 'auto',
                boxSizing: 'border-box',
                overflowX: 'hidden'
            }}
        >
            {
                logs.map((log) => {

                    // 整理顯示欄位
                    const showLogData = manageLogData(log?.data, cancelReasonMapping);

                    return (
                        <ListItem
                            key={log?.operatedAt}
                            sx={{
                                padding: '8px 0px',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            <Box sx={{ fontSize: '16px', marginBottom: '4px', fontWeight: 500 }}>{utility.formateTime(log?.operatedAt)}</Box>
                            <Box sx={{ fontSize: '16px', marginBottom: '4px', fontWeight: 700 }}>{log?.operator?.name}</Box>
                            <Box sx={{ paddingBottom: '12px' }}>
                                {
                                    Object.entries(showLogData || {}).map(([field, value]) => (
                                        <Box
                                            key={`${log?.operatedAt}_${field}`}
                                            sx={{
                                                fontSize: '14px', marginTop: '2px', marginBottom: '2px', wordBreak: 'break-word'
                                            }}
                                        >
                                            {
                                                ((field === 'adminMemo' || field === 'rfid') && !value) ? (
                                                    <>
                                                        Cleared&nbsp;
                                                        {field === 'adminMemo' && '備註'}
                                                        {field === 'rfid' && 'RFID'}
                                                    </>
                                                ) : (
                                                    <>
                                                        Updated&nbsp;
                                                        {editConfig.logFieldName[field]}
                                                        &nbsp;to&nbsp;
                                                        {field === 'packageStatus' && statusConfig[value]?.name}
                                                        {field === 'storehouse' && storehouseMapping[value]}
                                                        {field === 'shelves' && shelvesMapping[value]}
                                                        {field === 'weight' && `${value} kg`}
                                                        {field === 'cancelType' && cancelReasonMapping[value]}
                                                        {(field === 'adminMemo' && value) && (
                                                            <TextareaAutosize
                                                                disabled
                                                                style={{
                                                                    resize: 'none',
                                                                    borderWidth: 0,
                                                                    fontSize: '14px',
                                                                    width: '100%',
                                                                    padding: '4px 8px'
                                                                }}
                                                                value={value}
                                                            />
                                                        )}

                                                        {(field !== 'packageStatus'
                                                            && field !== 'storehouse'
                                                            && field !== 'shelves'
                                                            && field !== 'weight'
                                                            && field !== 'cancelType'
                                                            && field !== 'adminMemo'
                                                            && value !== '') && value}
                                                    </>
                                                )
                                            }
                                        </Box>
                                    ))
                                }
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: '#ddd'
                                }}
                            />
                        </ListItem>
                    );

                })
            }
        </List>
    );

}

ActionLog.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    logs: PropTypes.array,
    cancelReasonMapping: PropTypes.object,
    // shippingTypeMapping: PropTypes.object,
    storehouseMapping: PropTypes.object,
    shelvesMapping: PropTypes.object
};

ActionLog.defaultProps = {
    logs: [],
    cancelReasonMapping: {},
    // shippingTypeMapping: {},
    storehouseMapping: {},
    shelvesMapping: {}
};

export default ActionLog;
