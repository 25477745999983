import React, { useMemo, useCallback } from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import {
    Grid, Box
} from '@mui/material';

import { useFormikContext } from 'formik';

// eslint-disable-next-line import/namespace
import { getStatusOptions } from 'APP/configs/statusConfig';

import FormSelect from 'APP/components/form/FormSelect';
import FormInput from 'APP/components/form/FormInput';

import editConfig from 'APP/configs/editConfig';

function StatusInfo ({
    isEdit, cancelReasons
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();
    const statusOptions = useMemo(() => (getStatusOptions(record?.status)), [record?.status]);
    const isStatusCancel = !!(record?.status === 99);

    const notAuthenticatedOptions = useMemo(() => (cancelReasons.find((item) => item.id === 'not_authenticated')?.details?.map((item) => ({
        id: item.type,
        name: item.reason
    })) || []), [cancelReasons]);

    const handleChangeCancelType = useCallback((val) => {

        const value = val.target.value;

        if (value === 'other') formik.setFieldValue('cancelReason', '');
        formik.setFieldValue('cancelType', value);

    }, [formik]);

    return (
        <Grid container spacing={2} mt={0}>
            <Grid item xs={4} md={4}>
                <Box>
                    <FormSelect
                        labelName="處理狀態"
                        labelId="statusLabel"
                        options={statusOptions}
                        disabled={!isEdit || (record?.status === 50 && record?.shippingInfo?.shipping === editConfig.shippingCode.sf)}
                        value={formik.values.status}
                        handleChange={formik.handleChange('status')}
                        isEdit={isEdit}
                        formControlExtraStyle={{ maxWidth: 150 }}
                    />
                </Box>
            </Grid>
            {
                (formik.values.status === 90 || ((formik.values.status === 99 || isStatusCancel) && record?.cancelReason?.type)) && (
                    <Grid item xs={6} md={6}>
                        <Box>
                            <FormSelect
                                labelName="異常原因"
                                labelId="cancelTypeLabel"
                                options={cancelReasons}
                                value={formik.values.cancelType}
                                handleChange={handleChangeCancelType}
                                error={formik.errors.cancelType}
                                disabled={!isEdit || isStatusCancel || formik.values.status === 99}
                                isEdit={isEdit}
                                formControlExtraStyle={{ maxWidth: 200 }}
                            />
                        </Box>
                        {(formik.values.cancelType === 'other') && (
                            <Box sx={{ mt: 2 }}>
                                <FormInput
                                    labelName="異常原因"
                                    disabled={!isEdit || isStatusCancel}
                                    value={formik.values.cancelReason}
                                    handleChange={formik.handleChange('cancelReason')}
                                    error={formik.errors.cancelReason}
                                    // formControlExtraStyle={{ maxWidth: 200 }}
                                    multiline
                                />
                            </Box>
                        )}
                        {(formik.values.cancelType === 'not_authenticated') && (
                            <Box sx={{ mt: 2 }}>
                                <FormSelect
                                    labelName="取消原因備註"
                                    disabled={!isEdit || isStatusCancel}
                                    options={notAuthenticatedOptions}
                                    value={formik.values.detailType}
                                    handleChange={formik.handleChange('detailType')}
                                    error={formik.errors.detailType}
                                    formControlExtraStyle={{ maxWidth: 200 }}
                                />
                            </Box>
                        )}

                    </Grid>
                )
            }
        </Grid>
    );

}

StatusInfo.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    cancelReasons: PropTypes.array
};

StatusInfo.defaultProps = {
    cancelReasons: []
};

export default StatusInfo;
